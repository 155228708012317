::selection {
    background-color: #222;
    color: white;
}

::-webkit-scrollbar {
    width: 10px;
}

/* Track */
::-webkit-scrollbar-track {
    background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
    background: #888;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
    background: #555;
}

body, h1, h2, h3, h4, h5, h6, .is-h1, .is-h2, .is-h3 {
    font-family: $font-family-base;
    line-height: 1.1;
}

i {
    flex-shrink: 0;
}

.btn {
    border-radius: 0;
}

.fixed-top {
    z-index: 9992;
}

.paragraph.has-bg-color {
    padding: 4rem;
}

// Text BG color opacity override
.has-bg-color.paragraph.transparency {
    &.color-background {
        background-color: rgba($background-color, 0.9) !important;
    }

    &.color-text {
        background-color: rgba($text-color, 0.9) !important;
    }
    &.color-primary {
        background-color: rgba($primary-color, 0.9) !important;
    }
    &.color-secondary {
        background-color: rgba($secondary-color, 0.9) !important;
    }
}

.paragraph {
    font-family: $font-family-base;

    h1 {
        font-size: 2.5em;
        font-weight: 400;
        line-height: 1.1;
    }
}

.btn {
    font-weight: bold;
}

.border,
.border-top,
.border-bottom {
  border-color: #d1d1d1 !important;
}

// body.nav-is-hidden .navbar-header {
//     margin-left: 0
// }

// body.nav-is-hidden .nav-outermost-wrapper {
//     left: 0
// }

// body.nav-is-hidden .bb-site-wrapper {
//     padding-left: 0;
// }

.pagination {
    border-top: none;
}

.has-bg-color.color-primary {
    color: $white !important;
    background-color: $primary-color !important;
}

.navbar.has-bg-color.color-primary .navbar-primary * {
    color: $white !important;
}

.has-bg-color.color-primary * {
    color: $white !important;
}

.bordred.has-bg-color.color-primary {
    color: $white !important;
}

.bordred.has-bg-color.color-primary * {
    color: $white !important;
}

.btn-transdev.btn.color-primary {
    color: $white !important
}

.btn-transdev.btn.color-primary *,
.btn-transdev.btn.color-primary:hover *,
.btn-transdev.btn.color-primary:focus *,
.btn-transdev.btn.color-primary:active * {
    color: $white !important
}

.btn-transdev.btn-outline.btn.color-primary:hover,
.btn-transdev.btn-outline.btn.color-primary:focus,
.btn-transdev.btn-outline.btn.color-primary:active {
    color: $white !important
}

.btn-transdev.btn-outline.btn.color-primary:hover *,
.btn-transdev.btn-outline.btn.color-primary:focus *,
.btn-transdev.btn-outline.btn.color-primary:active * {
    color: $white !important
}

.navbar-header.has-bg-color.color-primary .navbar-toggler .icon-bar,
.navbar-header.has-bg-color.color-primary .navbar-toggler .icon-bar:after,
.navbar-header.has-bg-color.color-primary .navbar-toggler .icon-bar:before {
    background-color: $white !important;
}

.navbar-header.has-bg-color.color-primary .navbar-toggler:not(.collapsed) .icon-bar {
    background: transparent!important;
}

.col-lg-6, .col-lg-4, .col-lg-3 {
    .block-formation-info {
        ul {
            display: block!important;
        }
    }
}

mark {
    color: $primary-color;
    background-color: rgba(255,120,65,.07);
    border-radius: 5px;
    padding: .2em;
}
.icon-arrow-close {
    font-size: 35px;
    font-style: normal;
    &:before {
        content: "\2039";
    }
}

.icon-arrow-open {
    font-size: 35px;
    font-style: normal;
    &:before {
        content: "\203a";
    }
}

// Cloud
.is-desktop .cloud-edit.add-padding .navbar-secondary {
    margin-left: 80px;
    transition: all .15s linear;
}

a.panel-gray {
    &:after {
        font-size: 0!important;
        background-color: $primary-color;
    }
    background-color: #f3f5f7!important;
    color: #1e1e1e!important;
}

.has-bg-color.color-primary .menu-add-parameter .icon-setting {
    color: #d94717!important;
}

.cloud-dialog .popin-ok,
button.ui-button.ui-corner-all.ui-widget:not(.popin-cancel) {
    color: #fff !important;
    text-transform: uppercase;
}

.keynumber {
    &-wrapper {
        h2 {
            text-align: center;
            margin-bottom: 2rem;
            font-size: 36px;
        }
    }

    &-list {
        text-align: center;

        .is-home & {
            display: none;
        }

        img {
            width: 8rem;
            margin-bottom: 1rem;
        }

        .is-h3 {
            font-size: 3rem;
            font-weight: bold;
            padding-bottom: 0;
            line-height: 1;
            padding-top: 1rem;
            color: #454545;
            font-family: 'Karbon';
        }

        .keynumber-description {
            color: #636363;
            font-family: 'Karbon';
            line-height: 1;
            font-size: 20px;
        }
    }
}
