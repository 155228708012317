@media print {
    .autoblock-horizontal, .nav-outermost-wrapper, .download-pdf-block, #navbar-primary, #footer, .navbar-toggler, .btn-search, #bb-profiler-panel {
        display:none!important;
    }
    .breadcrumb>li {
        display: inline-block;
    }
    .bb-site-wrapper {
        padding-left: 0;
    }

    .row-item{
        float: left;
    }
    .col-lg-6 {
        width: 50%;
    }

    .block-formation-info ul {
        display: block!important;
        list-style-type: none;
        overflow: hidden;
    }

    .block-prerequisites {
        margin-top: 50px;
    }

    .block-formation-info ul li{
        float: left;
        width: 20%;
    }
    .navbar-header {
        display: block!important;
        width: 100%;
        margin-left: 0!important;
    }
    .navbar-header>.container-fluid {
        display: block!important;
        width: 100%;
    }
    .fixed-top {
        display:none !important;
        position: relative!important;
    }
    .bb-site-wrapper .main {
        padding: 0 0 0 0 !important;
    }

    .collapse:not(.show) {
        display: block!important;
    }

    h6.panel-title a {
        background-color: red!important;
        color: #fff!important;
    }
    .navbar-secondary {
        display: none!important;
    }
    .has-bg-color.color-15645791767367{
        display:none !important;
        background-color: transparent!important;
    }
    .bb-content {
        background-image: none!important;
    }
    .block-formation-banner {
        padding: 0!important;
        background-color: transparent!important;
        min-height: 0px!important;
    }
    .block-formation-banner {
        display: none !important;
        span.text-color-background {
            span{
                color: #1e1e1e!important;
            }
        }
    }
}