/* Custom fonts below */
/* Karbon */

@font-face {
  font-family: 'Karbon';
  src: url('fonts/Karbon/Karbon-Bold.eot');
  src: url('fonts/Karbon/Karbon-Bold.eot?#iefix') format('embedded-opentype'),
      url('fonts/Karbon/Karbon-Bold.woff2') format('woff2'),
      url('fonts/Karbon/Karbon-Bold.woff') format('woff'),
      url('fonts/Karbon/Karbon-Bold.ttf') format('truetype'),
      url('fonts/Karbon/Karbon-Bold.svg#Karbon-Bold') format('svg');
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: 'Karbon';
  src: url('fonts/Karbon/Karbon-Medium.eot');
  src: url('fonts/Karbon/Karbon-Medium.eot?#iefix') format('embedded-opentype'),
      url('fonts/Karbon/Karbon-Medium.woff2') format('woff2'),
      url('fonts/Karbon/Karbon-Medium.woff') format('woff'),
      url('fonts/Karbon/Karbon-Medium.ttf') format('truetype'),
      url('fonts/Karbon/Karbon-Medium.svg#Karbon-Medium') format('svg');
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: 'Karbon';
  src: url('fonts/Karbon/Karbon-Regular.eot');
  src: url('fonts/Karbon/Karbon-Regular.eot?#iefix') format('embedded-opentype'),
      url('fonts/Karbon/Karbon-Regular.woff2') format('woff2'),
      url('fonts/Karbon/Karbon-Regular.woff') format('woff'),
      url('fonts/Karbon/Karbon-Regular.ttf') format('truetype'),
      url('fonts/Karbon/Karbon-Regular.svg#Karbon-Regular') format('svg');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Karbon';
  src: url('fonts/Karbon/Karbon-Semibold.eot');
  src: url('fonts/Karbon/Karbon-Semibold.eot?#iefix') format('embedded-opentype'),
      url('fonts/Karbon/Karbon-Semibold.woff2') format('woff2'),
      url('fonts/Karbon/Karbon-Semibold.woff') format('woff'),
      url('fonts/Karbon/Karbon-Semibold.ttf') format('truetype'),
      url('fonts/Karbon/Karbon-Semibold.svg#Karbon-Semibold') format('svg');
  font-weight: 600;
  font-style: normal;
}

// Mixins
.font-karbon-regular {
  font-family: 'Karbon', verdana, helvetica, arial, sans-serif;
  font-style: normal;
  font-weight: 400;
}
.font-karbon-medium {
  font-family: 'Karbon', verdana, helvetica, arial, sans-serif;
  font-style: normal;
  font-weight: 500;
}
.font-karbon-semibold {
  font-family: 'Karbon', verdana, helvetica, arial, sans-serif;
  font-style: normal;
  font-weight: 600;
}
.font-karbon-bold {
  font-family: 'Karbon', verdana, helvetica, arial, sans-serif;
  font-style: normal;
  font-weight: 700;
}

/* MOVE */

@font-face {
  font-family: 'Move';
  src: url('fonts/Move/MOVE-Regular.eot');
  src: url('fonts/Move/MOVE-Regular.woff2') format('woff2'),
      url('fonts/Move/MOVE-Regular.woff') format('woff'),
      url('fonts/Move/MOVE-Regular.ttf') format('truetype');
  font-weight: 600;
  font-style: normal;
}

/*
CUSTOM SVG Font
*/

// VARS
$icon-transdev-close: "\e917";
$icon-transdev-menu: "\e916";
$icon-transdev-pedagogy: "\e915";
$icon-transdev-check: "\f00d";
$icon-transdev-at: "\e912";
$icon-transdev-quotes: "\e900";
$icon-transdev-play: "\e901";
$icon-transdev-goal: "\e902";
$icon-transdev-users: "\e903";
$icon-transdev-gender: "\e904";
$icon-transdev-clock: "\e905";
$icon-transdev-euro-thin: "\e913";
$icon-transdev-euro: "\e906";
$icon-transdev-tag: "\e907";
$icon-transdev-minus: "\e908";
$icon-transdev-plus: "\e909";
$icon-transdev-print: "\e90a";
$icon-transdev-download: "\e90b";
$icon-transdev-search: "\e90c";
$icon-transdev-pdf: "\e90d";
$icon-transdev-news: "\e90e";
$icon-transdev-map-marker: "\e90f";
$icon-transdev-calendar-check: "\e910";
$icon-transdev-calendar: "\e914";
$icon-transdev-home: "\e911";

@font-face {
  font-family: 'transdev';
  src:
    url('fonts/Transdev/transdev.ttf') format('truetype'),
    url('fonts/Transdev/transdev.woff') format('woff'),
    url('fonts/Transdev/transdev.svg#transdev') format('svg');
  font-weight: normal;
  font-style: normal;
}

.icon-transdev {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'transdev' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-transdev-close {
  &:before {
    content: $icon-transdev-close;
  }
}
.icon-transdev-menu {
  &:before {
    content: $icon-transdev-menu;
  }
}
.icon-transdev-pedagogy {
  &:before {
    content: $icon-transdev-pedagogy;
  }
}
.icon-transdev-check {
  &:before {
    content: $icon-transdev-check;
  }
}
.icon-transdev-at {
  &:before {
    content: $icon-transdev-at;
  }
}
.icon-transdev-quotes {
  &:before {
    content: $icon-transdev-quotes;
  }
}
.icon-transdev-play {
  &:before {
    content: $icon-transdev-play;
  }
}
.icon-transdev-goal {
  &:before {
    content: $icon-transdev-goal;
  }
}
.icon-transdev-users {
  &:before {
    content: $icon-transdev-users;
  }
}
.icon-transdev-gender {
  &:before {
    content: $icon-transdev-gender;
  }
}
.icon-transdev-clock {
  &:before {
    content: $icon-transdev-clock;
  }
}
.icon-transdev-euro-thin {
  &:before {
    content: $icon-transdev-euro-thin;
  }
}
.icon-transdev-euro {
  &:before {
    content: $icon-transdev-euro;
  }
}
.icon-transdev-tag {
  &:before {
    content: $icon-transdev-tag;
  }
}
.icon-transdev-minus {
  &:before {
    content: $icon-transdev-minus;
  }
}
.icon-transdev-plus {
  &:before {
    content: $icon-transdev-plus;
  }
}
.icon-transdev-print {
  &:before {
    content: $icon-transdev-print;
  }
}
.icon-transdev-download {
  &:before {
    content: $icon-transdev-download;
  }
}
.icon-transdev-search {
  &:before {
    content: $icon-transdev-search;
  }
}
.icon-transdev-pdf {
  &:before {
    content: $icon-transdev-pdf;
  }
}
.icon-transdev-news {
  &:before {
    content: $icon-transdev-news;
  }
}
.icon-transdev-map-marker {
  &:before {
    content: $icon-transdev-map-marker;
  }
}
.icon-transdev-calendar-check {
  &:before {
    content: $icon-transdev-calendar-check;
  }
}
.icon-transdev-calendar {
  &:before {
    content: $icon-transdev-calendar;
  }
}
.icon-transdev-home {
  &:before {
    content: $icon-transdev-home;
  }
}
