.footer {
    .footer-back {
        padding: 1.875em 0;
        text-align: center;
        a {
            display: inline-block;
            font-style: normal;
            font-weight: 600;
            font-size: .75em;
            text-transform: uppercase;
            text-decoration: none;
            color: $primary-color;

            &:before {
                content: "\fe3f";
                display: block;
                font-size: 1.83333333em;
            }
        }
    }
}