//Searchbar

.searchbar-wrapper {
    .d-flex {
        background-color: rgba($white, .6);
        border-radius: 6px;
    }

    .form-control {
        border-color: #d1d1d1;
        padding: 20px;
        height: 72px;
        font-size: 1.25em;
        border-radius: 0;
        color: #1d1d1d;

        &:focus {
          box-shadow: none;
          border: 0;
        }
    }

    .search-btn {
        background-color: $primary-color;
        border-color: $primary-color;
        color: $white;
        padding: 13px 20px;
        font-size: 1rem;
        text-transform: uppercase;
        font-weight: 600;
        width: 100%;
        flex: 10%;
    }
}

.list-result-wrapper {
    a {
        &:focus {
            text-decoration: none;
        }
        &:hover {
            text-decoration: none;
        }
    }
}
.list-result-wrapper>li {
        padding: 40px 0;
        border-bottom: 1px solid #d1d1d1;
    }

.ttl-is-results {
    margin-bottom: .8333em;
    font-size: 2.25em;
    font-style: normal;
    font-weight: 400;
}