// Social network

@use "sass:math";

.social-list {
    margin-bottom: 0;
    li a {
        display: inline-block;
        text-align: center;
        font-size: $social-icon-size;
        line-height: $social-btn-size;
        width: $social-btn-size;
        height: $social-btn-size;
        border-radius: 100%;
        border-width: $social-border-width;
        padding: 0;
        margin: 0;
    }
}

// Share

.btn-social-share {
    display: flex;
    
    &:not(:last-child) {
        margin-right: math.div($spacer, 2);
    }

    .fb-share-button {
        line-height: 1rem;
    }
}
