.panel-group .panel {
    margin-bottom: 10px
}

.panel-group {
    .panel-title {
        a {
            display: block;
            position: relative;
            padding: 0 60px 0 20px;
            height: 60px;
            line-height: 60px;
            font-family: Karbon, verdana, helvetica, arial, sans-serif;
            font-style: normal;
            font-weight: 400;
            font-size: 1.375em;
            background-color: $primary-color;
            color: #fff;
            transition: color .5s ease;
            &:after {
                content: "\e908";
                font-family: transdev!important;
                speak: none;
                font-style: normal;
                font-weight: 400;
                font-variant: normal;
                text-transform: none;
                -webkit-font-smoothing: antialiased;
                -moz-osx-font-smoothing: grayscale;
                display: inline-block;
                position: absolute;
                top: 0;
                right: 0;
                bottom: 0;
                width: 60px;
                height: 60px;
                line-height: 60px;
                text-align: center;
                color: #fff;
                border-left: 1px solid #fff
            }
            &:focus{
                text-decoration: none;
                color: #fff;
            }
            &:hover {
                text-decoration: none;
                color: #fff;
            }


        }
        a.collapsed {
                background-color: #f3f5f7;
                color: #1e1e1e;
                &:after {
                    content: "\e909";
                    background-color: $primary-color;
                    color: #fff
                }
                &:focus{
                   color: $primary-color;
                }
                &:hover{
                    color: $primary-color;
                }

            }
    }
    .panel-collapse {
        padding: 30px;
        border: 3px solid $primary-color;
        border-top: 0
    }
    .table-formation {
        margin: 0
    }
}