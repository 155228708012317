.article-title {
    h1 {
      font-size: 2.375rem;
      margin-bottom: 1.25rem;
      font-weight: 700;
      display: inline-block;

      &.new-tag {
        &:after {
          content: 'Nouveau';
          display: inline-block;
          padding: 5px 8px;
          font-size: 1rem;
          font-family: $font-family-base;
          font-weight: 600;
          text-transform: uppercase;
          background-color: $primary-color;
          color: $white;
          line-height: 1;
          color: #fff;
          text-align: center;
          white-space: nowrap;
          vertical-align: middle;
          border-radius: .25em;
          margin-left: 1rem;
        }
      }
    }

    .ref-number {

      &:before {
        content: "";
        margin-right: .75em;
        display: inline-block;
        width: 10px;
        height: 10px;
        font-size: 1.0625em;
        border-radius: 100%;
        background-color: $transdev-green;
      }
    }
  }