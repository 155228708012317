// Google Map

.map-wrapper {
    .google-map {
        width: 100%;
        height: 200px;
    }

    &.set-auto-height {
        height: 100%;
        min-height: $map-set-autoheight-min-h;

        .google-map {
            height: 100%;
        }
    }
}
