.breadcrumb-wrapper {
    padding: 25px 0;
    font-family: Karbon, verdana, helvetica, arial, sans-serif;
    font-weight: 500;
    font-size: 1rem;
    color: #1e1e1e;
    font-style: italic;
    margin: 0 0 .25cm;
    ul {
        padding: 0 1rem;
        margin-bottom: 0;
        background: transparent;
        li+li:before {
            color: #d1d1d1
        }
        a {
            color: #1e1e1e;
            &:focus{
                color: $primary-color;
                text-decoration: none
            }
            &:hover {
                color: $primary-color;
                text-decoration: none
            }
        }
    }
    .breadcrumb>li+li{
        &:before {
            content: "|\00a0";
            padding: 0 5px;
            color: #d1d1d1;
    }
    }
}