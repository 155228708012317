.section-pourcentage {
    position: relative;
    font-size: .875em;
    line-height: 1.3;
    border: 4px solid;
    border-radius: $border-radius-block;
    padding: 32px 20px;
    padding-left: 72px;

    &.text-primary {
      border-color: $primary-color;
    }

    .header {
        position: absolute;
        left: 20px;
        font-size: .875rem;

        .ttl {
            position: absolute;
            top: -10px;
            left: 25px;
            margin-bottom: 0;
            padding: 3px;
            font-family: Karbon,verdana,helvetica,arial,sans-serif;
            font-style: normal;
            font-weight: 600;
            line-height: 1;
            text-transform: uppercase;
            background-color: #fff;
            white-space: nowrap;
        }
        .icon-transdev {
            display: inline-block;
            line-height: 45px;
            text-align: center;
            width: 45px;
            height: 45px;
            border-radius: 45px;
            line-height: 39px;
            font-size: 20px;
            border: 3px solid;
            font-style: normal;
        }
    }
    .meta {
        font-weight: 600;
        font-size: .875rem;
        margin-top: 16px;
    }
}
