// Image

.figure {
    position: relative;
    display: block;

    &.image-auto-height,
    &.set-auto-height {
        img:not(.image-picto) {
            height: 100%;
            max-width: none;
        }
    }

    &.image-auto-width {
        img:not(.image-picto) {
            width: 100%;
            max-width: none;
        }
    }

    &.image-resize, &.image-fullwidth {
        .contentwrapper {
            overflow: hidden;
            position: absolute;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;

            .image-overlay {
                position: absolute;
                top: 0;
                right: 0;
                bottom: 0;
                left: 0;
                background-color: rgba(0, 0, 0, .25);
            }
        }

        img:not(.image-picto) {
            position: absolute;
        }
    }

    .image-picto {
        height: auto;
        width: auto;
        max-width: 20%;
        position: absolute;
        top: 50%;
        left: 50%;
        @include translate3d (-50%, -50%, 0);
    }

    &.media-image .contentwrapper {
        .above-content-wrapper {
            position: absolute;
            left: 0;
            right: 0;
            padding: $image-text-padding;
            text-align: left;
            z-index: 2;

            &.text-top {
                top: 0;
            }

            &.text-bottom {
                bottom: 0;
            }

            &.text-middle {
                top: 50%;
                @include transform(translateY(-50%));
            }

            &.hover-display {
                margin-top: 10px;
                @include transition(all 300ms linear);
            }

            figcaption {
                margin-bottom: $image-text-margin;
            }

        }

        .image-overlay.hover-display,
        .image-picto.hover-display,
        .buttonwrapper.hover-display,
        .lead.hover-display {
            @include opacity(0);
            @include transition(all 300ms linear);
        }

        &:hover {
            .hover-display {
                @include opacity(1);
                margin-top: 0;
            }
        }

        &.hover-none {
            .hover-display {
                @include opacity(1);
                margin-top: 0;
            }
        }
    }

    &.set-auto-height {
        height: 100%;
        min-height: $image-set-autoheight-min-h;

        background: {
            repeat: no-repeat;
            position: center;
            size: cover;
        }

        img {
            display: none;
        }
    }
}
