.block-formation-banner {
    padding: 4rem;
    background-color: rgba($primary-color, .8);
    display: flex !important;
    align-items: center;
    min-height: 420px;
}

.download-pdf-block a {
    text-decoration: none;
    padding: 10px 20px;
    border: 2px solid;
    font-weight: 700;
    text-transform: uppercase;
    text-align: center;
    border-radius: 12px;

    i {
        font-size: 1.75em;
        margin-right: 5px;
    }

    span {
        flex-grow: 1;
    }
}

.block-formation-info {
    li {
        &:not(:last-child) {
            border-right: 1px solid #d1d1d1;
        }

        i {
            font-size: 1.5rem;
        }
    }
}

.block-programme {
    position: relative;
    padding-top: $spacer * 2;

    &:before {
        content: '';
        display: block;
        height: 1px;
        width: 90px;
        background-color: #d1d1d1;
        position: absolute;
        left: 0;
        top: 0;
    }

    h3 {
        font-size: 20px;
        line-height: 1.3;
        font-weight: 600;
        padding-left: 2rem;
        position: relative;
        margin-bottom: 1rem;

        &:before {
            content: '\f00d';
            font-family: 'transdev';
            position: absolute;
            left: 0;
            color: $primary-color;
        }
    }

    .formation-details {
        line-height: 1.5;
        padding-left: 2rem;

        ul {
        padding-left: $spacer;
        list-style-type: circle;

        li {
            margin-bottom: .5rem;
        }
        }
    }
}

.block-objectives,
.block-prerequisites,
.block-evaluation,
.block-pedagogy {
    background-color: #F3F3F3;
    margin-top: 0 !important;

    .icon-transdev {
        width: 70px;
        height: 70px;
        border-radius: 100%;
        font-size: 44px;
        display: flex;
        align-items: center;
        justify-content: center;
        color: $white;
    }
}

.block-evaluation {
    h2 {
        color: $transdev-orange-alt;
    }

    .icon-transdev {
        background-color: $transdev-orange-alt;
        background-image: url('../../img/icon-check-list.png');
        background-repeat: no-repeat;
        background-position: center;
        background-size: 42px;
    }
}

.block-pedagogy {
    h2 {
        color: $transdev-purple;
    }

    .icon-transdev {
        background-color: $transdev-purple;
    }
}

.block-prerequisites {
    h2 {
        color: $transdev-green;
    }

    .icon-transdev {
        background-color: $transdev-green;
    }
}

.block-objectives {
    h2 {
        color: $transdev-red;
    }

    .icon-transdev {
        background-color: $transdev-red;
    }
}


.block-shortcut {
    .btn {
        &,
        &:not([href]) {
            padding: 13px 20px;
            border: 2px solid $white;
            color: $white;
            font-weight: 600;
            background: transparent;

            &:hover {
                background-color: $white;
            }

            i {
                color:inherit;
                transition: none;
            }
        }
    }
  }